import React from 'react'

import {
  Creative,
  Gallery,
  Hero,
  ImageWithText,
  ReviewSlider,
  Seperator,
  Signup,
  TourDates,
} from 'components/Blocks'
import Layout from 'utils/Layout'

const meta = {
  title: `The Bodyguard Musical UK Tour`,
  description: `The Bodyguard Musical UK Tour. Based on the Warner Bros. film. Screenplay by Lawrence Kasdan.`,
}

const Index = () => (
  <Layout title={meta.title} description={meta.description}>
    <Hero />
    <ReviewSlider />
    <ImageWithText />
    {/* <TourDates /> */}
    {/* <Creative data="cast" /> */}
    {/* <Seperator /> */}
    {/* <Creative data="creative" /> */}
    <Gallery />
    <Signup />
  </Layout>
)

export default Index
